import defaultMetaTags from "@/constants/defaultMetaTags";
import siteInfo from "@/constants/siteInfo";
import Head from "next/head";

const WebpageSchema = ({
  title = "",
  description = "",
  keywords = "",
  canonicalUrl = "",
}) => {
  return (
    <Head>
      <script
        id="WebpageSchema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title || defaultMetaTags?.title,
            description: description || defaultMetaTags?.description,
            ...(keywords && { keywords: keywords }),
            url: canonicalUrl || process.env.NEXT_PUBLIC_WEBSITE_URL,
            publisher: {
              "@type": "Organization",
              name: siteInfo?.siteName,
              url: process.env.NEXT_PUBLIC_WEBSITE_URL,
              logo: {
                "@type": "ImageObject",
                url: `${process.env.NEXT_PUBLIC_WEBSITE_URL}${siteInfo?.siteLogo}?size=1280*720`,
                height: 60,
                width: 160,
              },
            },
          }),
        }}
      />
    </Head>
  );
};

export default WebpageSchema;
